import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { Typography, Grid, Box } from '@material-ui/core';

import { NEW_PARTICIPANT_STATUS, PLUMBID_STATUS, ROLES } from 'constant';
import state from 'service/graphql/state';
import { SET_PLUMBID_DATES_MUTATION } from 'service/graphql';
import { LinkButton, Button, PBox } from 'legos';
import { PlumBidStatusCard } from './PlumBidStatusCard';
import { useNotification } from 'utils/hooks';
import { useAuthState } from 'service/store/authStore';
import { theme } from 'utils/theme';
import { EditPlumBidTimeAndPrice } from './EditPlumBidTimeAndPrice';
import { formatNumberToUSD } from 'utils';

const validateFinishDateRange = finishDate => {
  if (!finishDate) {
    return false;
  }

  return finishDate.isBetween(
    moment().set('minute', Math.floor(moment().get('minute') / 15) * 15 + 14),
    moment().add(7, 'd').set('hour', 23).set('minute', 59).set('second', 59)
  );
};

const validateStartDateRange = (startDate, finishDate) => {
  if (!startDate || !finishDate) {
    return false;
  }

  return startDate.isBetween(moment().set('minute', Math.floor(moment().get('minute') / 15) * 15 + 14), finishDate);
};

export const PlumBidStatus = ({ plumBid, refetch }) => {
  const [{ me }] = useAuthState();
  const isAdmin = me.roles.includes(ROLES.ADMIN);
  const isListingAgent = plumBid?.myInfoInPlumBid.role === ROLES.LISTING_AGENT;
  const isPlumBidCanceled = plumBid.status === PLUMBID_STATUS.CANCELED;
  const isPlumBidLive = plumBid.status === PLUMBID_STATUS.LIVE;
  const showEditOrRelaunchButton =
    (isAdmin || isListingAgent) && (plumBid.status === PLUMBID_STATUS.UPCOMING || isPlumBidLive || isPlumBidCanceled);

  const [editPlumBid, setEditPlumBid] = useState(false);
  const [finishDateTime, setFinishDateTime] = useState(plumBid.finishDateTime);
  const [startDateTime, setStartDateTime] = useState(plumBid.startDateTime);
  const [selectedOpeningPrice, setSelectedOpeningPrice] = useState(plumBid.openingPrice > 0 ? plumBid.openingPrice : 0);
  const [selectedReservePrice, setSelectedReservePrice] = useState(
    plumBid.reservedPrice > 0 ? plumBid.reservedPrice : 0
  );
  const showNotification = useNotification();
  const plumBidTimeLeft = useReactiveVar(state.plumBidsTimerLeftVar);
  const timeLeft = plumBidTimeLeft[`${plumBid.id}dashboard`];

  const renderTimeLeft = ms => {
    if (ms < 1000) {
      return '';
    }
    let s = Math.floor(ms / 1000);
    let m = Math.floor(s / 60);
    let h = Math.floor(m / 60);
    const d = Math.floor(h / 24);

    h -= d * 24;
    m -= (d * 24 + h) * 60;
    s -= ((d * 24 + h) * 60 + m) * 60;

    return (
      <Box display="flex" flexWrap="wrap">
        {d > 0 && (
          <PBox pr={0.5}>
            <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
              {`${d} ${d === 1 ? 'day' : 'days'}`}
            </Typography>
          </PBox>
        )}
        {h > 0 && (
          <PBox pr={0.5}>
            <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
              {`${h} ${h === 1 ? 'hour' : 'hours'}`}
            </Typography>
          </PBox>
        )}
        <PBox pr={0.5}>
          <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
            {`${m} ${m === 1 ? 'minute' : 'minutes'}`}
          </Typography>
        </PBox>
        {d === 0 && (
          <PBox>
            <Typography variant="body1" style={{ fontSize: 16, fontWeight: 700 }}>
              {`${s} ${s === 1 ? 'second' : 'seconds'}`}
            </Typography>
          </PBox>
        )}
      </Box>
    );
  };

  const [setPlumbidDates] = useMutation(SET_PLUMBID_DATES_MUTATION);

  const statusInfo = () => {
    if (plumBid.status === PLUMBID_STATUS.UPCOMING || plumBid.status === PLUMBID_STATUS.INCOMPLETE) {
      if (isFullService) {
        return {
          line1: 'plumBid will start ',
          line2: moment(plumBid.startDateTime).format('ddd, MMM DD [at] h:mm a '),
        };
      }
      if (plumBid.finishDateTime) {
        return {
          line1: 'plumBid will end ',
          line2: moment(plumBid.finishDateTime).format('ddd, MMM DD [at] h:mm a '),
        };
      }
      return { line1: '', line2: '' };
    }
    if (plumBid.status === PLUMBID_STATUS.LIVE && !!timeLeft) {
      return { line1: 'plumBid will finish in', line2: 'timeLeft' };
    }

    return { line1: 'plumBid Ended', line2: '' };
  };

  const isBuyerJoined = plumBid.buyerParty.some(item =>
    item.participants.edges.find(
      item =>
        item.node?.status === NEW_PARTICIPANT_STATUS.COMPLETED ||
        item.node?.status === NEW_PARTICIPANT_STATUS.WAITING_FOR_ONBOARDING
    )
  );

  const isFullService = plumBid.service.name === 'fullservice';

  const handleChangePlumBid = useCallback(
    ({ finishDateTime, startDateTime, selectedOpeningPrice, selectedReservePrice }) => {
      let isValid = false;
      if ((isListingAgent && !isAdmin) || (isAdmin && !isFullService && isBuyerJoined)) {
        isValid = validateFinishDateRange(moment(finishDateTime));
        if (!isValid) {
          showNotification({
            message: 'Please select time in the future',
            variant: 'error',
          });
          return;
        }
      }

      if (isAdmin) {
        if (isBuyerJoined) {

          isValid = validateFinishDateRange(moment(finishDateTime));
        }

        if (!isPlumBidLive && !isBuyerJoined) {

          isValid =
            validateStartDateRange(moment(startDateTime), moment(finishDateTime)) &&
            validateFinishDateRange(moment(finishDateTime)) &&
            +selectedOpeningPrice < +selectedReservePrice;
        }

        if (!isPlumBidLive && !isBuyerJoined && !startDateTime) {

          isValid = validateFinishDateRange(moment(finishDateTime)) && +selectedOpeningPrice < +selectedReservePrice;
        }

        if (isPlumBidLive) {
          isValid = validateFinishDateRange(moment(finishDateTime))
          if (isFullService) {
            isValid = validateFinishDateRange(moment(finishDateTime)) && +selectedOpeningPrice < +selectedReservePrice;
          }
        }

        if (isBuyerJoined && isFullService) {

          isValid = +selectedOpeningPrice < +selectedReservePrice;
        }

        if (!isValid) {
          if (
            !isPlumBidLive &&
            startDateTime &&
            !validateStartDateRange(moment(startDateTime), moment(finishDateTime))
          ) {
            if (moment(startDateTime).isBefore(moment())) {
              showNotification({
                message: 'Start date cannot be in the past.',
                variant: 'error',
              });
            } else {
              showNotification({
                message: 'Start date should be earlier than finish date',
                variant: 'error',
              });
            }
          }

          if (!validateFinishDateRange(moment(finishDateTime))) {

            showNotification({
              message: 'Please select Finish date in the future',
              variant: 'error',
            });
          }

          if (selectedOpeningPrice && selectedReservePrice && +selectedOpeningPrice >= +selectedReservePrice) {

            showNotification({
              message: 'Reserve price should be bigger than Opening price',
              variant: 'error',
            });
          }
          return;
        }
      }
      if (isValid) {
        setPlumbidDates({
          variables: {
            input: {
              ...(isAdmin
                ? {
                  plumbidId: plumBid.id,
                  finishDateTime: finishDateTime,
                  ...(((!isBuyerJoined && !isFullService) || isFullService) ? { startDateTime: startDateTime } : {}),
                  reservedPrice: startDateTime ? selectedReservePrice : 0,
                  openingPrice: startDateTime ? selectedOpeningPrice : 0,
                }
                : { plumbidId: plumBid.id, finishDateTime: finishDateTime }),
            },
          },
        })
          .catch(() => {
            showNotification({
              message: 'Something went wrong',
              variant: 'error',
            });
          })
          .then(({ data }) => {
            if (data?.setPlumbidDates.success) {
              refetch();
              showNotification({
                message: 'Changes are saved',
                variant: 'success',
              });
            }
            if (data?.setPlumbidDates.errors) {
              const error = data.setPlumbidDates.errors;
              showNotification({ error });
            }
          })
          .finally(setEditPlumBid(false));
      }
    },
    [
      isAdmin,
      isBuyerJoined,
      isFullService,
      isListingAgent,
      isPlumBidLive,
      plumBid.id,
      refetch,
      setPlumbidDates,
      showNotification,
    ]
  );

  useEffect(() => {
    if (plumBid.status === PLUMBID_STATUS.FINISHED && !isAdmin) {
      refetch();
    }
  }, [isAdmin, plumBid.status, refetch]);

  return (
    <Grid container item direction={plumBid.status === PLUMBID_STATUS.UPCOMING ? 'row' : 'column'}>
      <PBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        mt={0.5}
        style={{ flexWrap: 'wrap' }}
      >
        <PlumBidStatusCard plumBid={plumBid} timeLeft={timeLeft} />
        {(isAdmin || isListingAgent) && (
          <PBox
            px={2}
            py={1}
            mb={0.5}
            display="flex"
            borderRadius="3px"
            width="fit-content"
            alignItems="center"
            justifyContent="center"
            bgcolor={theme.palette.background.sundown}
          >
            <Typography variant="caption" style={{ color: theme.palette.common.white }}>
              {isFullService ? 'Full Service' : 'Multiples'}
            </Typography>
          </PBox>
        )}
      </PBox>
      <PBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={editPlumBid || isPlumBidCanceled ? 'column' : 'row'}
        mt={0.5}
      >
        <PBox mt={1} alignSelf="flex-start" width="100%">
          {!editPlumBid && (
            <PBox width="100%">
              <PBox display="flex" alignItems="center" justifyContent="space-between">
                <PBox pb={0.5}>
                  <Typography align="left" style={{ fontSize: 12, fontWeight: 500 }}>
                    {statusInfo().line1}
                  </Typography>
                </PBox>
                {showEditOrRelaunchButton && (
                  <PBox>
                    {isPlumBidCanceled ? (
                      <Button
                        title="Relaunch"
                        icon="Edit"
                        fullWidth
                        style={{ margin: '8px 0' }}
                        onClick={() => setEditPlumBid(true)}
                      />
                    ) : (
                      <LinkButton
                        icon="EditPencil"
                        iconSize={16}
                        color="link"
                        style={{
                          marginLeft: 20,
                          padding: 0,
                        }}
                        onClick={() => setEditPlumBid(true)}
                      />
                    )}
                  </PBox>
                )}
              </PBox>
              <PBox mb={1} mt={2}>
                {statusInfo().line2 === 'timeLeft' ? (
                  renderTimeLeft(timeLeft)
                ) : (
                  <Typography align="left" style={{ fontSize: 18, fontWeight: 700 }}>
                    {statusInfo().line2}
                  </Typography>
                )}
              </PBox>
              {isFullService && (isAdmin || isListingAgent) && (
                <>
                  <PBox pb={1}>
                    <Typography align="left" style={{ fontSize: 12, fontWeight: 500 }}>
                      Opening price:
                    </Typography>
                    <Typography align="left" style={{ fontSize: 14, fontWeight: 600 }}>
                      {formatNumberToUSD(plumBid.openingPrice, { hidePlusSign: true })}
                    </Typography>
                  </PBox>
                  <PBox pb={1}>
                    <Typography align="left" style={{ fontSize: 12, fontWeight: 500 }}>
                      Reserve price:
                    </Typography>
                    <Typography align="left" style={{ fontSize: 14, fontWeight: 600 }}>
                      {formatNumberToUSD(plumBid.reservedPrice, { hidePlusSign: true })}
                    </Typography>
                  </PBox>
                </>
              )}
            </PBox>
          )}
          {editPlumBid && (
            <EditPlumBidTimeAndPrice
              finishDateTime={finishDateTime}
              setFinishDateTime={setFinishDateTime}
              startDateTime={startDateTime}
              setStartDateTime={setStartDateTime}
              selectedOpeningPrice={selectedOpeningPrice}
              setSelectedOpeningPrice={setSelectedOpeningPrice}
              selectedReservePrice={selectedReservePrice}
              setSelectedReservePrice={setSelectedReservePrice}
              isBuyerJoined={isBuyerJoined}
              isFullService={isFullService}
              isPlumBidLive={isPlumBidLive}
            />
          )}
        </PBox>
        {showEditOrRelaunchButton ? (
          <PBox width={editPlumBid || isPlumBidCanceled ? '100%' : 'auto'}>
            {editPlumBid && (
              <PBox width="100%">
                <Button
                  fullWidth
                  title="Save"
                  icon="Accept"
                  style={{ margin: '8px 0' }}
                  disabled={
                    isFullService ? (startDateTime && (!selectedReservePrice || !selectedOpeningPrice)) : false
                  }
                  onClick={() =>
                    handleChangePlumBid({
                      finishDateTime,
                      startDateTime,
                      selectedOpeningPrice,
                      selectedReservePrice,
                    })
                  }
                />
                <Button
                  fullWidth
                  title="Cancel"
                  icon="Close"
                  variant="secondary"
                  onClick={() => setEditPlumBid(false)}
                />
              </PBox>
            )}
          </PBox>
        ) : null}
      </PBox>

      {plumBid.status === PLUMBID_STATUS.UPCOMING && !isFullService && (
        <PBox py={0.5} mt={1} width="100%">
          <Typography align="left" style={{ color: theme.palette.text.warning }}>
            plumBid will start once sellers and at least two buyers are done with the onboarding
          </Typography>
        </PBox>
      )}
    </Grid>
  );
};
