import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Typography, IconButton, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import { Icons, PBox, CurrencyInput } from 'legos';
import { formatNumberToUSD } from 'utils';
import { ROLES } from 'constant';
import { useAuthState } from 'service/store';

export const EditPlumBidTimeAndPrice = ({
  finishDateTime,
  setFinishDateTime,
  startDateTime,
  setStartDateTime,
  selectedOpeningPrice,
  setSelectedOpeningPrice,
  selectedReservePrice,
  setSelectedReservePrice,
  isBuyerJoined,

  isFullService,
  isPlumBidLive,
}) => {
  const [{ me }] = useAuthState();
  const isAdmin = me.roles.includes(ROLES.ADMIN);
  const showInputPrice = isAdmin && (!isBuyerJoined || isFullService);

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <PBox my={1}>
          <Typography align="left" variant="h6">
            End time and date
          </Typography>
        </PBox>
        <DatePicker
          showDaysOutsideCurrentMonth
          fullWidth
          orientation="portrait"
          variant="dialog"
          format="MM/DD/yyyy"
          minDate={moment()}
          maxDate={moment().add(7, 'day')}
          value={finishDateTime}
          onChange={date => {
            setFinishDateTime(date);
          }}
          inputVariant="filled"
          style={{ marginBottom: 8 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <Icons.CalendarNew />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TimePicker
          orientation="portrait"
          fullWidth
          id="time"
          value={finishDateTime}
          onChange={date => {
            setFinishDateTime(date);
          }}
          inputVariant="filled"
          minutesStep={15}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <Icons.Clock />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
      {isAdmin && !isPlumBidLive && !isBuyerJoined && (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <PBox my={1}>
            <Typography align="left" variant="h6">
              Start time and date (for Full Service)
            </Typography>
          </PBox>

          <DatePicker
            showDaysOutsideCurrentMonth
            fullWidth
            orientation="portrait"
            variant="dialog"
            format="MM/DD/yyyy"
            minDate={moment()}
            maxDate={moment().add(7, 'day')}
            value={startDateTime}
            onChange={date => {
              setStartDateTime(date);
            }}
            clearable={!isBuyerJoined}
            inputVariant="filled"
            style={{ marginBottom: 8 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Icons.CalendarNew />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TimePicker
            orientation="portrait"
            fullWidth
            id="time"
            value={startDateTime}
            onChange={date => {
              setStartDateTime(date);
            }}
            clearable={!isBuyerJoined}
            inputVariant="filled"
            minutesStep={15}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <Icons.Clock />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      )}

      {showInputPrice && (
        <>
          <PBox my={1}>
            <Typography align="left" variant="h6">
              Opening price
            </Typography>
          </PBox>
          <CurrencyInput
            value={
              startDateTime
                ? formatNumberToUSD(selectedOpeningPrice, {
                  hidePlusSign: true,
                })
                : formatNumberToUSD(0, {
                  hidePlusSign: true,
                })
            }
            onChange={value => setSelectedOpeningPrice(value)}
            textAlign="end"
            autoFocus
            startAdornment="EditDocument"
            fontSize={14}
            disabled={!startDateTime || isPlumBidLive}
          />
          <PBox my={1}>
            <Typography align="left" variant="h6">
              Reserve price
            </Typography>
          </PBox>
          <CurrencyInput
            value={
              startDateTime
                ? formatNumberToUSD(selectedReservePrice, {
                  hidePlusSign: true,
                })
                : formatNumberToUSD(0, {
                  hidePlusSign: true,
                })
            }
            onChange={value => setSelectedReservePrice(value)}
            textAlign="end"
            fontSize={14}
            autoFocus
            disabled={!startDateTime}
            startAdornment="EditDocument"
          />
        </>
      )}
    </>
  );
};
